import { isUserVerified } from "$lib/services/store";
import type { User, UserResponse } from "@/src/types/users";

const setVerifiedUser = (userData: User): void => {
  const { username, ageConsent, termsConsent, phoneNumber } = userData;
  const isValid = !!(username && ageConsent && termsConsent && phoneNumber);

  isUserVerified.set(isValid);
};

export const handleResponse = async (response: Response): Promise<UserResponse> => {
  const contentType = response.headers.get('Content-Type');
  const data = contentType && contentType.includes('application/json')
    ? await response.json()
    : null;

  if (response.ok || response.status === 201) {
    setVerifiedUser(data);

    return { data };
  }

  return { errors: Array.isArray(data.message) ? data.message : [{ message: data.message }] };
};
